<template>
    <!-- 风控用户详情 userRiskDetail -->
    <div class="container">
        <div class="header" v-if="isMobile==0">
            <router-link class="btn" to="/stocks">{{$t('aside.list')[1]}}</router-link>
            <i class="iconfont2 icon-right_arrow"></i>
            <span>{{$t('stocks.userRiskDetail')}}</span>
        </div>

        <div class="content" :class="{content_phone:isMobile==1}">
            <div class="title">
                <i class="iconfont2 icon-shaixuan"></i>
                {{$t('stocks.userRiskDetail')}}
            </div>
            <div class="searchBox">
                <el-input
                    :placeholder="$t('common.searchExchange')"
                    prefix-icon="el-icon-search"
                    class="inp"
                    v-model="exchange"
                    @keyup.enter.native="search">
                </el-input>
            </div>

            <div class="title">
                <i class="iconfont2 icon-shaixuan"></i>
                {{$t('stocks.userRiskDetail')}}
                （{{$t('stocks.showOnlyTrades',{user:userName})}}）
            </div>
            <div class="tableBox">
                <el-table
                    :key="lang"
                    height="100%"
                    :data="tableList">
                    <el-table-column
                    header-align="center"
                    align="center"
                    prop="exchange"
                    :label="$t('common.exchange')"
                    min-width="80">
                    </el-table-column>
                    <el-table-column
                    header-align="center"
                    align="center"
                    prop="pair"
                    :label="$t('common.pair')"
                    min-width="100">
                    </el-table-column>
                    <el-table-column
                    class-name="column"
                    header-align="center"
                    align="center"
                    prop="direction"
                    :label="$t('common.direction')"
                    min-width="100">
                        <template slot-scope="scope">
                            <span v-if="scope.row.direction=='long'" class="g_green">{{ $t("stocks.goLong") }}</span>
                            <span v-else-if="scope.row.direction=='short'" class="g_red">{{ $t("stocks.goShort") }}</span>
                            <div class="g_btn tag">{{scope.row.leverage}}.00x</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                    header-align="center"
                    align="center"
                    prop="num"
                    :label="$t('common.num')"
                    min-width="100">
                    </el-table-column>
                    <el-table-column
                    header-align="center"
                    align="center"
                    prop="pirce"
                    :label="$t('common.pirce')"
                    min-width="100">
                    </el-table-column>
                    <el-table-column
                    header-align="center"
                    align="center"
                    prop="gas"
                    :label="$t('common.gas')"
                    min-width="100">
                    </el-table-column>
                    <el-table-column
                    header-align="center"
                    align="center"
                    prop="transactionTime"
                    :label="$t('common.transactionTime')"
                    min-width="120">
                    </el-table-column>
                    <el-table-column
                    header-align="center"
                    align="center"
                    prop="hedgingStatus"
                    :label="$t('common.hedgingStatus')"
                    min-width="110">
                        <template slot-scope="scope">
                            <img class="tableIcon" v-if="scope.row.hedgingStatus==1" src="@/assets/images/hedged.png" alt="">
                            <img class="tableIcon" v-else src="@/assets/images/notHedged.png" alt="">
                            {{scope.row.hedgingStatus==1?$t('common.hedged'):$t('common.notHedged')}}
                        </template>
                    </el-table-column>
                    <el-table-column
                    header-align="center"
                    align="center"
                    prop="hedgingDirection"
                    :label="$t('common.hedgingDirection')"
                    min-width="100">
                        <template slot-scope="scope">
                            {{scope.row.hedgingDirection==1?$t('stocks.goLong'):$t('stocks.goShort')}}
                        </template>
                    </el-table-column>
                    <el-table-column
                    header-align="center"
                    align="center"
                    prop="hedgingExecutionPrice"
                    :label="$t('common.hedgingExecutionPrice')"
                    min-width="120">
                    </el-table-column>
                    
                    <el-empty slot="empty"
                    :image="emptyImg"
                    :image-size="120"
                    :description="$t('common.noData')"
                    />
                </el-table>
            </div>
            <div class="pagination">
                <el-pagination
                  background
                  layout="sizes, prev, pager, next"
                  :total="total"
                  :page-size="pageSize"
                  :page-sizes="[20, 50, 100]"
                  :current-page="currentPage"
                  @current-change="handleCurrentChange"
                  @size-change="handleSizeChange"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<style lang="stylus" scoped>
@import 'userRiskDetail.styl';
</style>
<script>
import { mapGetters } from 'vuex';
export default {
    computed:{
        ...mapGetters(['account','isMobile','lang']),
    },
    data() {
        return {
            emptyImg: require("@/assets/images/noorder.png"),
            
            exchange: '',
            exchangeRes: '',
            tableList: [
                // {
                //     exchange: 'Binance',
                //     pair: 'BTC/USDT',
                //     direction: 'long',
                //     leverage: '10',
                //     num: '1',
                //     pirce: '10000',
                //     gas: '0.01',
                //     transactionTime: '2021-01-01 12:00:00',
                //     hedgingStatus: 1,
                //     hedgingDirection: 1,
                //     hedgingExecutionPrice: '10000',
                // },
                // {
                //     exchange: 'Binance',
                //     pair: 'BTC/USDT',
                //     direction: 'short',
                //     leverage: '5',
                //     num: '1',
                //     pirce: '10000',
                //     gas: '0.01',
                //     transactionTime: '2021-01-01 12:00:00',
                //     hedgingStatus: 0,
                //     hedgingDirection: 0,
                //     hedgingExecutionPrice: '10000',
                // },
            ],
            // 分页
            currentPage: 1,
            pageSize: 20,
            total: 0,
        }
    },
    created(){
        this.userName=this.$route.query.userName;
        this.getList();
    },
    methods: {
        search(){
            this.exchangeRes=this.exchange;
            this.currentPage=1;
            this.getList();
        },
        addUser(){

        },
        getList(){

        },

        handleDelete(row){
            this.$confirm(this.$t('tip.delTip'), this.$t('tip.tips'), {
                confirmButtonText: this.$t('common.confirm'),
                cancelButtonText: this.$t('common.cancel'),
                center: true,
                customClass: 'deleteOk',
                showClose:false,
            }).then(() => {
                console.log(row);
            }).catch(() => {
            });
        },
        handleView(row){
            // console.log(row);
            this.$router.push({
                path: '/userRiskDetail',
                query: {
                    userName: row.userName,
                    id: row.id,
                }
            })
        },

        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.currentPage = 1;
            this.getList();
        },
    },
}
</script>